

import * as React from "react";
import * as PageLayout from "../components/PageLayout.bs.js";
import * as HomepassLogo from "../components/HomepassLogo.bs.js";

function Index(Props) {
  return React.createElement(PageLayout.make, {
              className: "flex bg-white items-center justify-center h-screen w-screen",
              title: "Welcome - Homepass",
              children: React.createElement(HomepassLogo.make, {
                    className: "w-1/4"
                  })
            });
}

var make = Index;

var $$default = Index;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
