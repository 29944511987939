

import * as React from "react";
import * as Head from "next/head";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function PageLayout(Props) {
  var className = Props.className;
  var title = Props.title;
  var children = Props.children;
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("div", tmp, React.createElement(Head.default, {
                  children: null
                }, React.createElement("meta", {
                      charSet: "utf-8"
                    }), React.createElement("meta", {
                      content: "width=device-width, initial-scale=1.0",
                      name: "viewport"
                    }), React.createElement("meta", {
                      content: "homepass-legal",
                      name: "name"
                    }), React.createElement("meta", {
                      content: "homepass privacy legal",
                      name: "description"
                    }), React.createElement("title", undefined, title)), children);
}

var make = PageLayout;

var $$default = PageLayout;

export {
  make ,
  $$default ,
  $$default as default,
  
}
/* react Not a pure module */
